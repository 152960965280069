(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/log/assets/javascripts/logger.js') >= 0) return;  svs.modules.push('/components/core/log/assets/javascripts/logger.js');


svs = svs || {};

(function(svs, config, jQuery, document) {
  'use strict';

  svs.core = svs.core || {};
  svs.core.log = svs.core.log || {};


  if (!window.console) {
    window.console = {};
  }

  if (!window.console.debug) {
    window.console.debug = function() {};
  }

  if (!window.console.info) {
    window.console.info = function() {};
  }

  if (!window.console.log) {
    window.console.log = function() {};
  }

  if (!window.console.warn) {
    window.console.warn = function() {};
  }

  if (!window.console.error) {
    window.console.error = function() {};
  }

  var URL_MAX_LENGTH = 2000;
  var _levels = ['off', 'error', 'warn', 'info', 'debug', 'log'];
  var _passThroughMethods = [
    'assert',
    'clear',
    'count',
    'dir',
    'dirxml',
    'exception',
    'group',
    'groupCollapsed',
    'groupEnd',
    'profile',
    'profileEnd',
    'table',
    'time',
    'timeEnd',
    'trace'
  ];
  var _loggers = {};
  var _loggersInstances = {};
  var _lastFlushTime;
  var _lastMessageAddedTime;
  var _serverMessageQueue = [];
  var _noop = function() {};

  svs.core.log.MessageType = {
    log: 'l',
    pageview: 'pv',
    timing: 'tm',
    metric: 'me',
    event: 'ev',
    trans: 'tr',
    item: 'i',
    customEvent: 'ce',
    targetPayload: 'tp'
  };

  svs.core.log.Logger = function(module) {
    if (!(this instanceof svs.core.log.Logger)) {
      throw new Error('Object must be initialised with the "new" keyword, e.g. new svs.core.log.Logger("mymodule").');
    }

    if (_loggersInstances[module]) {
      return _loggersInstances[module];
    }

    var rule;

    this.module = module;

    if (config.rules) {
      for (var i = 0, length = config.rules.length; i < length; i++) {
        rule = config.rules[i];

        if (new RegExp(rule.pattern).test(module)) {
          _loggers[module] = _loggers[module] || [];

          _doesLogLevelExist(module, rule);
        }
      }
    }

    if (!_loggers[module]) {
      _loggers[module].push({
        module: module,
        minLevel: 'off',
        writeTo: {
          name: 'console',
          type: 'console'
        },
        logger: this
      });
    }
    _setupPassthroughMethods(module, this);
    _setupLogMethods(module, this);

    _loggersInstances[module] = this;

    return this;
  };

  svs.core.log.getLogger = function(module) {
    if (_loggersInstances[module]) {
      return _loggersInstances[module];
    }
    return new svs.core.log.Logger(module);
  };

  svs.core.log.Levels = (function() {
    var allLevels = {};
    var idx = _levels.length - 1;
    while (idx >= 0) {
      allLevels[_levels[idx]] = idx;
      idx -= 1;
    }

    return allLevels;
  })();

  svs.core.log.Targets = (function() {
    var target;
    var targets = { console: null, server: null };

    if (config.targets) {
      for (var p in config.targets) {
        if (Object.prototype.hasOwnProperty.call(config.targets, p)) {
          target = config.targets[p];
          target.name = p;
          targets[p] = target;
        }
      }
    }

    return targets;
  })();

  svs.core.log.logLevel = function(module, target, newLevel, asString) {
    var args = Array.prototype.slice.call(arguments);
    var logger;
    module = args.shift();
    newLevel = undefined;
    asString = false;

    if (module instanceof svs.core.log.Logger) {
      module = module.module;
    }

    logger = _loggers[module];
    if (!logger) {
      throw new Error('Module with name "' + module + '" not initialized. Please use "new svs.core.log.Logger("' + module + '")".');
    }

    if (args.length === 0) {
      throw new Error('No target specified when setting/getting log level for module with name "' + module +
          '". Please call this method with the target set to any of the svs.core.log.Targets.');
    }

    target = args.shift();
    if (!target || !target.name || !Object.prototype.hasOwnProperty.call(svs.core.log.Targets, target.name)) {
      throw new Error('Unknown target specified, please use any of the existing svs.core.log.Targets.');
    }

    if (args.length > 0) {
      if (typeof args[0] === 'boolean') {
        asString = args.shift();
      } else {
        newLevel = args.shift();
      }
    }

    var targetLogger = [];

    for (var logIndex = 0; logIndex < logger.length; logIndex++) {
      if (logger[logIndex].writeTo === target.name) {
        targetLogger.push(logger[logIndex]);
      }
    }

    if (targetLogger.length > 1) {
      targetLogger = targetLogger[-1];
    } else {
      targetLogger = targetLogger[0];
    }

    if (newLevel === undefined) {
      var numeric = targetLogger && targetLogger.minLevel !== undefined ? _levels.indexOf(targetLogger.minLevel) : 0;

      if (asString === true) {
        return _levels[numeric];
      }

      return numeric;
    }

    if (targetLogger) {
      targetLogger.minLevel = _levels[_coerceLogLevel(newLevel)];

      _setupPassthroughMethods(module, targetLogger.logger);
      _setupLogMethods(module, targetLogger.logger);
    }
  };

  if (svs.core.config.data.svsconfig.env !== 'development') {
    var genericErrorHandler = new svs.core.log.Logger('generic');
    window.onerror = function(message, filename, lineNo, colNo, error) {
      genericErrorHandler.error('msg="' + message + '", url=' + filename + ', co=' + colNo + ', li=' + lineNo + ', st=' + (error ? error.stack : ''));
    };
  }

  $(window).on('beforeunload', function() {
    _flushMessages(true);
  });
  $(window).on('unload', function() {
    _flushMessages(true);
  });

  jQuery(document).ready(function() {
    setTimeout(function() {
      _flushMessages(true);
      setInterval(function() {
        _flushMessages();
      }, svs.core.log.Targets.server && svs.core.log.Targets.server.sendIntervalSoft || 500);
    }, 250);
  });

  function _doesLogLevelExist(module, rule) {
    if (!(_loggers[module].minLevel === rule.minLevel && _loggers[module].writeTo === rule.writeTo)) {
      _loggers[module].push({
        module: module,
        minLevel: rule.minLevel,
        writeTo: rule.writeTo,
        logger: _loggers[module]
      });
    }
  }

  function _coerceLogLevel(newLevel) {
    if (newLevel === undefined) {
      throw new Error('Level is undefined');
    }
    if (typeof newLevel === 'string') {
      var index = _levels.indexOf(newLevel);
      if (index === -1) {
        throw new Error(newLevel + ' is not a valid Logger Level');
      }
      newLevel = index;
    }
    if (
      newLevel < svs.core.log.Levels.off ||
      newLevel > svs.core.log.Levels.log
    ) {
      throw new Error('Logger level of ' + newLevel + ' is not a valid value');
    }
    return newLevel;
  }


  function _setupPassthroughMethods(module, that) {
    var consoleLogLevel = svs.core.log.logLevel(module, svs.core.log.Targets.console);
    for (var idx = 0, length = _passThroughMethods.length; idx < length; idx++) {
      if (consoleLogLevel === svs.core.log.Levels.off) {
        that[_passThroughMethods[idx]] = _noop;
      } else {
        that[_passThroughMethods[idx]] = _setupConsoleLogMethod(_passThroughMethods[idx], console);
      }
    }
  }

  function _setupLogMethods(module, that) {
    var idx = _levels.length - 1;

    while (idx > 0) {
      _logLevelSetup(_levels[idx], idx, module, that);
      idx -= 1;
    }
  }

  function _logLevelSetup(methodName, logLevel, module, that) {
    var currConsoleLevel = svs.core.log.logLevel(module, svs.core.log.Targets.console);
    var currServerLevel = svs.core.log.logLevel(module, svs.core.log.Targets.server);
    var consoleMetadata = methodName + ': [' + module + ']';

    if (currServerLevel === 0) {

      if (currConsoleLevel >= logLevel) {
        that[methodName] = _setupConsoleLogMethod(methodName, console, consoleMetadata);
      } else {
        that[methodName] = _noop;
      }
    } else {
      that[methodName] = _setupConsoleAndServerLogMethod(module, methodName, console, logLevel, currConsoleLevel, currServerLevel);
    }
  }

  function _setupConsoleLogMethod(methodName, console, metadata) {
    if (typeof console === 'undefined') {
      return _noop;
    } else if (console[methodName] === undefined) {
      if (console.log !== undefined) {
        return _bindMethodToConsole(console, 'log', metadata);
      }
      return _noop;
    }
    return _bindMethodToConsole(console, methodName, metadata);
  }

  function _bindMethodToConsole(console, methodName, metadata) {
    var method = console[methodName];
    if (method.bind === undefined) {
      if (Function.prototype.bind === undefined) {
        return _functionBindingWrapper(method, console, metadata);
      }
      try {
        if (metadata) {
          return Function.prototype.bind.call(console[methodName], console, metadata);
        }
        return Function.prototype.bind.call(console[methodName], console);
      } catch (e) {
        return _functionBindingWrapper(method, console, metadata);
      }
    } else {
      if (metadata) {
        return console[methodName].bind(console, metadata);
      }
      return console[methodName].bind(console);
    }
  }

  function _functionBindingWrapper(func, context, metadata) {
    return function() {
      var args = Array.prototype.slice.call(arguments);
      if (metadata) {
        args.unshift(metadata);
      }
      Function.prototype.apply.apply(func, [context, args]);
    };
  }

  function _setupConsoleAndServerLogMethod(
    module,
    methodName,
    console,
    loglevel,
    currConsoleLevel,
    currServerLevel
  ) {
    return function() {
      if (currConsoleLevel >= loglevel) {
        var consoleMetadata = methodName + ': [' + module + ']';
        _functionBindingWrapper(console[methodName], console, consoleMetadata).apply(console, arguments);
      }

      if (currServerLevel >= loglevel) {
        var message = new svs.core.log.Message(svs.core.log.MessageType.log);
        message.loglevel = loglevel;

        for (var argIndex = 0; argIndex < arguments.length; argIndex++) {
          var currArg = arguments[argIndex];
          if (currArg !== null && typeof currArg === 'object' && Object.prototype.hasOwnProperty.call(currArg, 'metadata')) {
            message.meta = arguments[argIndex].metadata;
          }
        }

        message.message = arguments;

        _addToServerQueue(module, message);
      }
    };
  }


  svs.core.log.Message = function(type) {
    this.type = type;
    this.time = new Date().toISOString();
    this.formFactor = svs.core.data.capabilities && svs.core.data.capabilities.analyticsFormFactor || 'null';
    this.module = svs.core.data.module || '';
    this.site = svs.core.config.data.svsconfig.site;
    this.destPage = fixMaxLengthString(location.pathname) + fixMaxLengthString(location.search);
    switch (type) {
      case svs.core.log.MessageType.log:
        this.formFactor = svs.core.data.capabilities && svs.core.data.capabilities.formFactor || 'null';
        this.loglevel = undefined;
        this.uri = fixMaxLengthString(location.pathname);
        this.queryString = fixMaxLengthString(location.search);
        this.message = undefined;
        this.meta = undefined;
        this.serialize = function() {
          return {
            t: this.type,
            ti: this.time,
            ll: this.loglevel,
            ff: this.formFactor,
            m: this.module,
            u: this.uri,
            q: this.queryString,
            msg: this.message,
            site: this.site,
            meta: this.meta
          };
        };
        return this;
      case svs.core.log.MessageType.pageview:
        this.pageTitle = document.title;
        this.screenResolution = screen.width + 'x' + screen.height;
        this.viewport = svs.core.detect.viewport.width() + 'x' + svs.core.detect.viewport.height();
        this.orientation = svs.core.detect.viewport.width() > svs.core.detect.viewport.height() ? 'l' : 'p';
        this.serialize = function() {
          return {
            t: this.type,
            ti: this.time,
            ff: this.formFactor,
            dp: this.destPage,
            dt: this.pageTitle,
            sr: this.screenResolution,
            vp: this.viewport,
            so: this.orientation,
            site: this.site
          };
        };
        return this;
      case svs.core.log.MessageType.timing:
        this.totalPageLoadTime = -1;
        this.pageDownloadTime = -1;
        this.serverResponseTime = -1;
        this.interactivePage = -1;
        this.serialize = function() {
          return {
            t: this.type,
            ti: this.time,
            ff: this.formFactor,
            dp: this.destPage,
            plt: this.totalPageLoadTime,
            pdt: this.pageDownloadTime,
            srt: this.serverResponseTime,
            dit: this.interactivePage,
            site: this.site
          };
        };
        return this;
      case svs.core.log.MessageType.metric:
        this.eventCategory = undefined;
        this.eventAction = undefined;
        this.eventValue = undefined;
        this.eventLabel = undefined;
        this.serialize = function() {
          return {
            t: this.type,
            ti: this.time,
            ff: this.formFactor,
            dp: this.destPage,
            tc: this.eventCategory,
            tv: this.eventAction,
            tms: this.eventValue,
            tl: this.eventLabel,
            site: this.site
          };
        };
        return this;
      case svs.core.log.MessageType.event:
        this.eventCategory = undefined;
        this.eventAction = undefined;
        this.eventLabel = undefined;
        this.eventValue = 1;
        this.serialize = function() {
          return {
            t: this.type,
            ti: this.time,
            ff: this.formFactor,
            dp: this.destPage,
            ec: this.eventCategory,
            ea: this.eventAction,
            el: this.eventLabel,
            ev: this.eventValue,
            site: this.site
          };
        };
        return this;
      case svs.core.log.MessageType.customEvent:
        this.eventName = undefined;
        this.eventData = undefined;
        this.serialize = function() {
          return {
            t: this.type,
            ti: this.time,
            ff: this.formFactor,
            dp: this.destPage,
            en: this.eventName,
            ed: this.eventData,
            site: this.site
          };
        };
        return this;
      case svs.core.log.MessageType.trans:
        this.transId = undefined;
        this.transTotalAmount = 0;
        this.transShipping = 0;
        this.transTax = 0;
        this.serialize = function() {
          return {
            t: this.type,
            ti: this.time,
            ff: this.formFactor,
            dp: this.destPage,
            tid: this.transId,
            tr: this.transTotalAmount,
            ts: this.transShipping,
            tt: this.transTax,
            site: this.site
          };
        };
        return this;
      case svs.core.log.MessageType.item:
        this.transId = undefined;
        this.itemName = undefined;
        this.itemCode = undefined;
        this.itemVariation = undefined;
        this.itemPrice = 0;
        this.itemQuantity = 0;
        this.serialize = function() {
          return {
            t: this.type,
            ti: this.time,
            ff: this.formFactor,
            dp: this.destPage,
            tid: this.transId,
            in: this.itemName,
            ic: this.itemCode,
            iv: this.itemVariation,
            ip: this.itemPrice,
            iq: this.itemQuantity,
            site: this.site
          };
        };
        return this;
      case svs.core.log.MessageType.targetPayload:
        this.pe = undefined;
        this.tnta = undefined;
        this.serialize = function() {
          return {
            t: this.type,
            ti: this.time,
            ff: this.formFactor,
            dp: this.destPage,
            pe: this.pe,
            tnta: this.tnta,
            site: this.site
          };
        };
        return this;
      default:
        throw new Error('Unknown type when creating Message instance.');
    }
  };

  svs.core.log.track = function(moduleName, message) {
    if (!moduleName || typeof moduleName !== 'string') {
      throw new Error('No module name specified in track-method.');
    }
    if (!message || !(message instanceof svs.core.log.Message)) {
      throw new Error('No message-object provided in track-method.');
    }

    if (!config.suppressLoggingMetricsToServer) {
      _addToServerQueue(moduleName, message);
    }
  };

  function _addToServerQueue(module, message) {
    if (!module || typeof module !== 'string') {
      throw new Error('No module specified.');
    }
    if (!message || !(message instanceof svs.core.log.Message)) {
      throw new Error('Unknown or undefined message in method _addToServerQueue');
    }

    var msg;

    if (message.type === svs.core.log.MessageType.log) {
      msg = message.message;
      if (typeof msg === 'object') {
        try {
          if (msg.length) {
            var convertedMessage = '';
            for (var i = 0; i < msg.length; i++) {
              if (typeof msg[i] === 'string' || typeof msg[i] === 'number' || typeof msg[i] === 'boolean') {
                convertedMessage += convertedMessage.length === 0 ? msg[i] : ', ' + msg[i];
              } else {
                convertedMessage += convertedMessage.length === 0 ? JSON.stringify(msg[i]) : ', ' + JSON.stringify(msg[i]);
              }
            }

            msg = convertedMessage;
          } else {
            msg = JSON.stringify(msg);
          }
        } catch (error) {
          msg = '[Failed to convert logmessage to string in logger.js],Msg:' + error.message + ',stack:' + error.stack;
        }
      } else {
        msg = msg.toString();
      }
      message.message = fixMaxLengthString(msg);
    }

    try {
      var queueMessage = {
        v: svs.core.config.data.svsconfig.ver,
        m: module,
        data: message.serialize()
      };

      var maxQueueSize = svs.core.log.Targets.server && svs.core.log.Targets.server.maxQueueSize || 500;

      while (_serverMessageQueue.length >= maxQueueSize) {
        _serverMessageQueue.shift();
      }

      _serverMessageQueue.push(encodeURIComponent(JSON.stringify(queueMessage)));
      _lastMessageAddedTime = new Date();
    } catch (error) {
    }
  }

  svs.core.log.flush = function() {
    _flushMessages(true);
  };

  function _flushMessages(flushNow) {
    try {
      var finalUrl;
      var tmpUrl;
      var counter;
      var urlFull;
      var image;
      var currentTime;
      var hasExceededSoftLimit;
      var hasExceededHardLimit;

      if (_serverMessageQueue && _serverMessageQueue.length > 0) {
        currentTime = new Date();
        hasExceededSoftLimit = _lastMessageAddedTime <= currentTime.setMilliseconds(currentTime.getMilliseconds() + (svs.core.log.Targets.server.sendIntervalSoft || 200));
        currentTime = new Date();
        hasExceededHardLimit = _lastFlushTime <= currentTime.setMilliseconds(currentTime.getMilliseconds() + (svs.core.log.Targets.server.sendIntervalHard || 1000) * 0.75);

        if (flushNow || hasExceededSoftLimit || hasExceededHardLimit) {
          while (_serverMessageQueue.length > 0) {
            finalUrl = svs.core.log.Targets.server.host + '/i.gif';
            tmpUrl = finalUrl;
            counter = 0;
            urlFull = false;

            while (_serverMessageQueue.length > 0 && !urlFull) {
              tmpUrl += (counter === 0 ? '?' : '&') + counter + '=' + _serverMessageQueue[0];

              if (tmpUrl.length < URL_MAX_LENGTH) {
                _serverMessageQueue.shift();
                finalUrl = tmpUrl;
                counter++;
              } else {
                if (counter === 0) {
                  _serverMessageQueue.shift();
                  finalUrl = tmpUrl.substr(0, URL_MAX_LENGTH);
                }
                urlFull = true;
              }
            }

            if (!navigator.sendBeacon || !navigator.sendBeacon(finalUrl)) {
              image = new Image();
              image.width = image.height = 1;
              image.src = finalUrl;
              image.onload = image.onerror = function() { 
                image.onload = image.onerror = null;
              };
            }
          }

          _lastFlushTime = new Date();
          _lastMessageAddedTime = null;
        }
      }
    } catch (error) {
    }
  }

  function fixMaxLengthString(stringToFix) {
    var maxLength = svs.core.log.Targets.server && svs.core.log.Targets.server.maxLogMessageLength || 247;

    if (stringToFix.length > maxLength) {
      stringToFix = stringToFix.substring(0, maxLength) + '...';
    }
    return stringToFix;
  }

})(svs, svs.core.config.data.svsconfig.logconfigClient, $, document);


 })(window);